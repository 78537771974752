import React, { useEffect, useState, useRef } from "react";
import { RootState } from "store";
import { useSelector, shallowEqual, TypedUseSelectorHook } from "react-redux";
import Pusher from "pusher-js";
import { logger } from "utils";

Pusher.logToConsole = false;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function usePusher(Component, props = {}) {
  const [pusherInstance, setPusherInstance] = useState(null);

  const { currentEnvironment } = useAppSelector(
    ({ team }) => ({
      currentEnvironment: team.currentEnvironment,
    }),
    shallowEqual
  );

  useEffect(() => {
    var pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY, {
      cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
    });

    setPusherInstance(pusher);

    pusher.connection.bind("error", function (err) {
      if (err?.error?.data?.code === 4004) {
        logger(">>> detected limit error");
      } else if (err?.data?.code === 1006) {
        // Websocket: CLOSED ABNORMALLY
      }
    });

    pusher.connection.bind("disconnected", function (err) {
      logger(">>> disconnected");
    });

    pusher.connection.bind("connected", function (err) {
      logger(">>> connected", pusher.sessionID);
    });

    return function cleanup() {
      pusher.disconnect();
      setPusherInstance(null);
    };
  }, [currentEnvironment]);

  if (pusherInstance) {
    return <Component pusher={pusherInstance} {...props} />;
  } else {
    return null;
  }
}
