import EmptyState from "../../components/reusables/EmptyState";
import { DataTable, useDateHelpers, BasicCard } from "ui";
import { useEffect, useRef } from "react";

const RecentK = ({ lastKEvents, lastKEventsLoaded, ...props }) => {
  const tableRef = useRef(null);

  const { timeAgo, getFormattedDate } = useDateHelpers();

  useEffect(() => {
    tableRef.current.manualSetLoading(!lastKEventsLoaded);
  }, [lastKEventsLoaded]);

  useEffect(() => {
    tableRef.current.refresh();
  }, [lastKEvents]);

  return (
    <BasicCard title={`Recent 10`} description="">
      <DataTable
        ref={tableRef}
        config={{
          componentWrapperClasses: "rounded-none border-0",
          disableHeader: true,
          filterable: false,
          columns: [
            {
              label: "Event",
              identifier: "event_type",
              resolver: (d) => {
                return (
                  <>
                    <div className="text-sm text-gray-900 dark:text-gray-50">
                      {d.event_type}
                    </div>
                  </>
                );
              },
              sortable: false,
              filterable: {
                type: "text",
              },
            },
            {
              label: "#",
              identifier: "_time",
              resolver: (d) => {
                return (
                  <>
                    <div className="text-sm text-gray-900 dark:text-gray-50">
                      {getFormattedDate(
                        d._time,
                        false,
                        "MMM dd, yyyy, hh:mm:ss a"
                      )}
                    </div>
                    <div className="text-xs truncate text-gray-500 dark:text-gray-400">
                      {timeAgo(d._time)}
                    </div>
                  </>
                );
              },
              sortable: false,
              filterable: {
                type: "text",
              },
            },
          ],
        }}
        sortColumn={"event_count"}
        perPage={1}
        EmptyState={() => {
          return <EmptyState description="No events yet" />;
        }}
        dataSource={async (params) => {
          return {
            data: lastKEvents,
            pagination: {
              total: 0,
            },
          };
        }}
      />
    </BasicCard>
  );
};

export default RecentK;
