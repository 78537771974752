import { themeScheme } from "utils";
import { classNames, PulseLoader } from "ui";
import NextLink from "../../navigation/nextLink";

const ThreePointCard = ({
  loaded = true,
  title = "Total Events Today",
  goTo = "/",
  goToTitle = "View",
  dataPoint,
  ...props
}) => {
  return (
    <div
      className={classNames(themeScheme.border, "overflow-hidden rounded-lg")}
    >
      <div className="p-5">
        <div className="flex items-center">
          <div className="w-0 flex-1">
            <dl>
              <dt
                className={classNames(
                  themeScheme.secondaryText,
                  "text-xs font-medium truncate uppercase"
                )}
              >
                {title}
              </dt>
              <dd className="mt-1 lowercase h-12">
                {!loaded && <PulseLoader />}
                {loaded && <>{dataPoint}</>}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div className={classNames(themeScheme.secondaryBg, "px-5 py-3 hidden")}>
        <NextLink href={goTo}>{goToTitle}</NextLink>
      </div>
    </div>
  );
};

export default ThreePointCard;
