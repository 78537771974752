import { useSelector, shallowEqual, TypedUseSelectorHook } from "react-redux";
import { DateTime } from "luxon";
import { analyticsCanViewEventsAnalytics } from "services";
import usePusher from "../../components/wrappers/pusher";
import { useEffect, useState } from "react";
import {
  DateInput,
  useBetaForm,
  useDateHelpers,
  Heading,
  PulseLoader,
  BasicCard,
  classNames,
} from "ui";
import { RootState } from "store";
import { logger, themeScheme } from "utils";
import LastEvent from "./lastEvent";
import RecentK from "./recentK";
import TopK from "./topK";
import EventsToday from "./eventsToday";
import MainWrapper from "../../components/wrappers/mainWrapper";
import EventsTimeseriesGraph from "../../components/graphs/eventsTimeseriesGraph";

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

function DailyReport({ pusher, ...props }) {
  const { getCurrentTime, startAndEnds, dateTimeInUTC } = useDateHelpers();

  const { currentEnvironment, currentTeam, user } = useAppSelector(
    ({ team, auth }) => ({
      currentEnvironment: team.currentEnvironment,
      currentTeam: team.currentTeam,
      user: auth.user,
    }),
    shallowEqual
  );

  const HeadingActions = () => {
    return (
      <>
        <DateInput
          id="startTime"
          onChange={(v) => {
            eventsFilterForm.setField("startTime", v);
            submitForm();
          }}
          defaultValue={eventsFilterForm.getField("startTime")}
          errorText={eventsFilterForm.errors.get("startTime")}
          placeholder="Enter identifier"
          userTimezone={user?.profile?.timezone}
        />
      </>
    );
  };

  // Date Picker Form
  const eventsFilterForm = useBetaForm({
    startTime: DateTime.local().toISO(),
    endTime: DateTime.local().toISO(),
    filterStartTime: null,
    filterEndTime: null,
  });

  const [lastKEvents, setLastKEvents] = useState([]);
  const [lastKEventsLoaded, setLastKEventsLoaded] = useState(false);
  const [topKEvents, setTopKEvents] = useState([]);
  const [topKEventsLoaded, setTopKEventsLoaded] = useState(false);
  const [timeseriesEvents, setTimeseriesEvents] = useState([]);
  const [timeseriesEventsLoaded, setTimeseriesEventsLoaded] = useState(false);
  const [prevEnvironment, setPrevEnvironment] = useState(null);
  const [rowsMatched, setRowsMatched] = useState(0);

  const fetchRecentKAnalytics = async (filterStartTime, filterEndTime) => {
    try {
      setLastKEventsLoaded(false);
      if (!currentEnvironment) return;

      let lastKRes = await analyticsCanViewEventsAnalytics(
        currentEnvironment["name"],
        {
          presentation: "tabular",
          startTime: filterStartTime,
          endTime: filterEndTime,
          limit: 10,
        }
      );

      lastKRes = lastKRes.data.map((l) => {
        const { data, ...rest } = l;
        return { ...rest, ...data };
      });

      setLastKEvents(lastKRes);
    } catch (error) {
      logger("error", error);
    } finally {
      setLastKEventsLoaded(true);
    }
  };

  const fetchTimeseries = async (filterStartTime, filterEndTime) => {
    setRowsMatched(-1);
    setTimeseriesEventsLoaded(false);

    try {
      let timeseriesRes = await analyticsCanViewEventsAnalytics(
        currentEnvironment["name"],
        {
          presentation: "timeseries",
          startTime: filterStartTime,
          endTime: filterEndTime,
          timeSize: "30m",
        }
      );

      setTimeseriesEvents(timeseriesRes);

      // console.log("timeseriesRes", timeseriesRes?.status?.rowsMatched);
      setRowsMatched(timeseriesRes?.status?.rowsMatched);

      if (timeseriesRes?.status?.rowsMatched) {
        fetchRecentKAnalytics(filterStartTime, filterEndTime);
        fetchTopKAnalytics(filterStartTime, filterEndTime);
      }
    } catch (error) {
    } finally {
      setTimeseriesEventsLoaded(true);
    }
  };

  const fetchTopKAnalytics = async (filterStartTime, filterEndTime) => {
    setTopKEventsLoaded(false);
    try {
      let topKRes = await analyticsCanViewEventsAnalytics(
        currentEnvironment["name"],
        {
          presentation: "topk",
          topkValue: 10,
          startTime: filterStartTime,
          endTime: filterEndTime,
          timeSize: "1m",
        }
      );

      setTopKEvents(topKRes.data);
    } catch (error) {
    } finally {
      setTopKEventsLoaded(true);
    }
  };

  const submitForm = async () => {
    const startAndEndsResult = startAndEnds(eventsFilterForm.value.startTime);

    const filterStartTime = dateTimeInUTC(startAndEndsResult.start);
    const filterEndTime = dateTimeInUTC(startAndEndsResult.end);
    eventsFilterForm.setField("filterStartTime", filterStartTime);
    eventsFilterForm.setField("filterEndTime", filterEndTime);

    if (!currentEnvironment) return;

    fetchTimeseries(filterStartTime, filterEndTime);
  };

  useEffect(() => {
    if (currentTeam && currentEnvironment) {
      const streamChannel = `neptune-${currentTeam["teams*uuid"]}-${currentEnvironment["key"]}`;
      var channel = pusher.subscribe(streamChannel);

      channel.bind("new-event-message", function (data) {
        submitForm();
      });

      if (!prevEnvironment) {
        setPrevEnvironment(currentEnvironment);
      }

      if (prevEnvironment) {
        if (prevEnvironment["key"] !== currentEnvironment["key"]) {
          const prevStreamChannel = `neptune-${currentTeam["teams*uuid"]}-${prevEnvironment["key"]}`;
          pusher.unsubscribe(prevStreamChannel);
          setPrevEnvironment(currentEnvironment);
        }
      }

      return function cleanup() {
        pusher.unsubscribe(streamChannel);
      };
    }
  }, [currentEnvironment]);

  useEffect(() => {
    const startAndEndsResult = startAndEnds(getCurrentTime().toISO());
    eventsFilterForm.setField("startTime", startAndEndsResult.start);
    eventsFilterForm.setField(
      "filterStartTime",
      dateTimeInUTC(startAndEndsResult.start)
    );
    eventsFilterForm.setField(
      "filterEndTime",
      dateTimeInUTC(startAndEndsResult.end)
    );
    submitForm();
  }, []);

  return (
    <>
      <MainWrapper
        fixed={
          <Heading
            paddingClasses="py-3 px-6"
            title="Daily Report"
            actions={<HeadingActions />}
          />
        }
      >
        {rowsMatched > 0 && (
          <div className="space-y-8">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              <EventsToday
                timeseriesEvents={timeseriesEvents}
                timeseriesEventsLoaded={timeseriesEventsLoaded}
              />
              <LastEvent
                lastKEvents={lastKEvents}
                lastKEventsLoaded={lastKEventsLoaded}
              />
            </div>

            <div className="grid gap-5 sm:grid-cols-1">
              <BasicCard title={`Activity`} description="">
                <EventsTimeseriesGraph
                  filterStartTime={eventsFilterForm.getField("filterStartTime")}
                  filterEndTime={eventsFilterForm.getField("filterEndTime")}
                  currentEnvironment={currentEnvironment}
                  height="400px"
                />
              </BasicCard>
            </div>

            <div className="grid gap-5 sm:grid-cols-2">
              <TopK
                topKEvents={topKEvents}
                topKEventsLoaded={topKEventsLoaded}
              />
              <RecentK
                lastKEvents={lastKEvents}
                lastKEventsLoaded={lastKEventsLoaded}
              />
            </div>
          </div>
        )}

        {rowsMatched === -1 && (
          <div
            className={classNames(
              "w-full h-full py-32 flex flex-col justify-center place-content-center items-center rounded-md",
              themeScheme.border
            )}
          >
            <div className="flex items-center justify-center">
              <span className="relative inline-flex">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-sky-500 bg-white dark:bg-slate-800 transition ease-in-out duration-150 cursor-not-allowed ring-1 ring-slate-900/10 dark:ring-slate-200/20"
                  disabled
                >
                  Fetching Events..
                </button>
                <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                </span>
              </span>
            </div>
          </div>
        )}

        {rowsMatched === 0 && (
          <div
            className={classNames(
              "w-full h-full py-32 flex flex-col justify-center place-content-center items-center rounded-md",
              themeScheme.border
            )}
          >
            <h2 className="font-semibold">
              We couldn't find any events for the selected date.
            </h2>
            <h3
              className={classNames(
                "mt-5 animate-bounce",
                themeScheme.secondaryText
              )}
            >
              Waiting for events...
            </h3>
          </div>
        )}
      </MainWrapper>
    </>
  );
}

const DailyReportWithPusher = ({ ...props }) => {
  return usePusher(DailyReport);
};

export default DailyReportWithPusher;
