import ThreePointCard from "../../components/reusables/cards/threePointCard";

const EventsToday = ({ timeseriesEvents, timeseriesEventsLoaded }) => {
  return (
    <ThreePointCard
      loaded={timeseriesEventsLoaded}
      title="Total Events Today"
      dataPoint={(() => {
        return (
          <div className="text-xl font-medium">
            {timeseriesEvents.status ? timeseriesEvents.status.rowsMatched : 0}
          </div>
        );
      })()}
      goTo={`/`}
      goToTitle="View"
    />
  );
};

export default EventsToday;
