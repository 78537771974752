import EmptyState from "../../components/reusables/EmptyState";
import { DataTable, BasicCard } from "ui";
import { useEffect, useRef } from "react";

const TopK = ({ topKEvents, topKEventsLoaded, ...props }) => {
  const tableRef = useRef(null);

  useEffect(() => {
    tableRef.current.manualSetLoading(!topKEventsLoaded);
  }, [topKEventsLoaded]);

  useEffect(() => {
    tableRef.current.refresh();
  }, [topKEvents]);

  return (
    <BasicCard title={`Top 10`} description="">
      <DataTable
        ref={tableRef}
        config={{
          componentWrapperClasses: "rounded-none border-0",
          disableHeader: true,
          filterable: false,
          columns: [
            {
              label: "Event",
              identifier: "key",
              resolver: (d) => {
                return d["key"];
              },
              sortable: false,
              filterable: {
                type: "text",
              },
            },
            {
              label: "#",
              identifier: "count",
              resolver: (d) => {
                return d["count"];
              },
              sortable: false,
              filterable: {
                type: "text",
              },
            },
          ],
        }}
        sortColumn={"event_count"}
        perPage={1}
        EmptyState={() => {
          return <EmptyState description="No events yet" />;
        }}
        dataSource={async (params) => {
          return {
            data: topKEvents,
            pagination: {
              total: 0,
            },
          };
        }}
      />
    </BasicCard>
  );
};

export default TopK;
