import ThreePointCard from "../../components/reusables/cards/threePointCard";
import { useEffect, useState } from "react";
import { classNames, useDateHelpers } from "ui";
import { themeScheme } from "utils";

const LastEvent = ({ lastKEvents, lastKEventsLoaded }) => {
  const [lastEvent, setLastEvent] = useState(null);
  const { getFormattedDate, timeAgo } = useDateHelpers();

  useEffect(() => {
    if (lastKEvents.length > 0) {
      setLastEvent(lastKEvents[0]);
    } else {
      setLastEvent(null);
    }
  }, [lastKEvents]);

  return (
    <ThreePointCard
      loaded={lastKEventsLoaded}
      title={`Last Event`}
      dataPoint={(() => {
        return (
          <>
            {lastEvent && (
              <>
                <div className="text-base font-medium">
                  {lastEvent.event_type}
                </div>
                <div
                  className={classNames(
                    themeScheme.secondaryText,
                    "text-sm font-medium"
                  )}
                >
                  {getFormattedDate(
                    "2022-04-17T18:00:00Z",
                    false,
                    "hh:mm:ss a"
                  )}
                </div>
              </>
            )}
            {!lastEvent && <div className="mt-1 text-xl font-medium">NA</div>}
          </>
        );
      })()}
      goTo={`/`}
      goToTitle="View"
    />
  );
};

export default LastEvent;
