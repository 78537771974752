import type { NextPage } from "next";
import Head from "next/head";
import EnvironmentWrapper from "../components/wrappers/environment";
import DailyReportWithPusher from "../app/dailyReport";

const Home: NextPage = () => {
  return (
    <EnvironmentWrapper>
      <Head>
        <title>Neptune</title>
      </Head>
      <DailyReportWithPusher />
    </EnvironmentWrapper>
  );
};

export default Home;
